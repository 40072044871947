* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --selective-yellow: hsl(42, 94%, 55%);
  --eerie-black-1: hsl(0, 0%, 9%);
  --eerie-black-2: hsl(180, 3%, 7%);
  --quick-silver: hsl(0, 0%, 65%);
  --radical-red: hsl(351, 83%, 61%);
  --light-gray: hsl(0, 0%, 80%);
  --isabelline: hsl(36, 33%, 94%);
  --gray-x-11: hsl(0, 0%, 73%);
  --kappel_15: hsla(170, 75%, 41%, 0.15);
  --platinum: hsl(0, 0%, 90%);
  --gray-web: hsl(0, 0%, 50%);
  --black_80: hsla(0, 0%, 0%, 0.8);
  --white_50: hsla(0, 0%, 100%, 0.5);
  --black_50: hsla(0, 0%, 0%, 0.5);
  --black_30: hsla(0, 0%, 0%, 0.3);
  --kappel: #0062ff;
  --white: hsl(0, 0%, 100%);
  --gradient: linear-gradient(
    -90deg,
    hsl(151, 58%, 46%) 0%,
    hsl(170, 75%, 41%) 100%
  );
  --ff-league_spartan: "League Spartan", sans-serif;
  --ff-poppins: "Poppins", sans-serif;
  --fs-1: 4.2rem;
  --fs-2: 3.2rem;
  --fs-3: 2.3rem;
  --fs-4: 1.8rem;
  --fs-5: 1.5rem;
  --fs-6: 1.4rem;
  --fs-7: 1.3rem;
  --fw-500: 500;
  --fw-600: 600;
  --section-padding: 75px;
  --shadow-1: 0 6px 15px 0 hsla(0, 0%, 0%, 0.05);
  --shadow-2: 0 10px 30px hsla(0, 0%, 0%, 0.06);
  --shadow-3: 0 10px 50px 0 hsla(220, 53%, 22%, 0.1);
  --radius-pill: 500px;
  --radius-circle: 50%;
  --radius-3: 3px;
  --radius-5: 5px;
  --radius-10: 10px;
  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

/* ================================================================ */
/* Navbar */
/* ================================================================ */

.navbar {
  display: flex;
  background-color: #fff;
  box-shadow: 0 6px 15px 0 hsla(0, 0%, 0%, 0.05);
}
.navbar ul {
  display: flex;
  gap: 2.5rem;
}

.navbar ul li {
  list-style: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.navbar ul li:hover {
  color: orange;
}

.navbar svg:hover path {
  fill: orange;
}

/* ================================================================ */
/* Home */
/* ================================================================ */

.typeAni {
  font-size: 1.2rem;
}

#menubar {
  display: none;
}

@media (max-width: 600px) {
  #signup {
    display: none;
  }
}
@media (max-width: 992px) {
  #menubar {
    display: block;
  }
  #dasboard_option {
    display: none;
  }
}
@media (min-width: 992px) {
  .home_section {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .typeAni {
    font-size: 2.2rem;
  }
}

/* ================================================================ */
/* Certificate */
/* ================================================================ */
.certificate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.certificate {
  width: 100%;
  max-width: 800px;
  padding: 40px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.certificate-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 2rem;
}

.logo {
  width: 110px;
}

.certificate-title {
  font-size: 2rem;
  text-align: center;
}

.certificate-body {
  text-align: center;
  margin-top: 20px;
}

.certificate-subtitle {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.certificate-recipient {
  font-size: 2rem;
  margin-bottom: 20px;
}

.certificate-description {
  font-size: 1.2rem;
  margin: 0 20px;
}

.certificate-footer {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
}

.qr-code,
.signature,
.msme-logo {
  width: 100px;
  margin-bottom: 10px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: black;
  margin-bottom: 10px;
}

.issue-label {
  margin-bottom: 10px;
}

.print-button-container {
  margin-top: 20px;
  text-align: center;
}

.print-button {
  padding: 10px 20px;
  background-color: orange;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.print-button:hover {
  background-color: darkorange;
}

@media print {
  .print-button-container {
    display: none;
  }
}

.footer-section img,
.footer-section p,
.footer-section hr {
  margin: 0;
  padding: 0;
  line-height: 1; /* Reduces the space between lines */
}

.footer-section hr.line {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100px; /* Ensures the width of the line */
  height: 1px;
  background-color: black;
  border: none;
}

.footer-section p {
  margin-top: 5px;
  font-size: 1rem;
}

.footer-section .issue-label {
  margin-bottom: 5px;
}

.footer_text p:hover {
  text-decoration: underline;
  cursor: pointer;
}
/* ================================================================ */
/* Products */
/* ================================================================ */

.dashboard-nav,
.dashboard-slider,
.dash-card {
  display: flex;
  background-color: #fff;
  box-shadow: 0 6px 15px 0 hsla(0, 0%, 0%, 0.05);
}

.dashboard-slider ul li {
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.dashboard-slider ul li:hover {
  color: orange;
}
svg {
  cursor: pointer;
}

.dash-card {
  width: 15rem;
  height: 5rem;
  display: flex;
  justify-content: center;
}

.dash-card p {
  color: orange;
}

/* ================================================================ */
/* FooterBar */
/* ================================================================ */

.footer-bar {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.label {
  font-size: 0.6rem;
}

@media (min-width: 770px) {
  .footerMenuBar {
    display: none;
  }
}

/* ================================================================ */
/* Certificate */
/* ================================================================ */

.certificate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  scale: 1;
}

/* Styles for extra small screens */
@media (max-width: 480px) {
  .certificate-container {
    scale: 0.4;
  }
}

@media (max-width: 768px) {
  .certificate {
    width: 100%; /* Full width on smaller screens */
    padding: 1rem; /* Reduced padding */
  }

  .certificate-title {
    font-size: 2rem; /* Smaller font size on mobile */
  }

  .certificate-body h2 {
    font-size: 1.5rem; /* Smaller font size on mobile */
  }

  .certificate-footer img {
    max-width: 80px; /* Smaller logos on mobile */
  }

  .certificate-container {
    scale: 0.5;
  }
}

/* Styles for larger screens */
@media (max-width: 1200px) {
  .certificate-container {
    scale: 0.5;
  }
}
